table {
  border-collapse: collapse;
  /* Ensures borders are neat */
  width: 100%;
}

table tr,
table td {
  border: 1px solid black;
  /* Sets a border for each row and cell */
  text-align: center;
}
.print-header {
  display: none !important;
}
@media screen {
  
}

@media print {
  .print-header {
    display: block !important;
    text-align: center;
  }
 .MuiAutocomplete-root{
  display: none;
 }
  table {
    width: 100%;
    max-width: 100%;
    border: solid;
    table-layout: fixed;
  }
  
  #print-ignore,
  #customized-dialog-title,
  #order-view-close-btn,
  button {
    display: none;
  }

  .print-page-break {
    page-break-before: always;

  }
  body { width: 1mm }

}
@page { 
  size:A4; 
  margin: 0cm;
}