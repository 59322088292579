@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
    box-sizing: border-box;
}

.login_form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.login-bg {
    padding: 0;
    margin: 0;
    /* background-color: #03080e; */
    /* background: url('./bg3.jpg') no-repeat 49% 76%; */
    background-image: url(https://images.unsplash.com/photo-1638272181967-7d3772a91265?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80); 
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: poppins;
    position: relative;
}

.circles {
    width: 400px;
    height: 400px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.circle1 {
    width: 300px;
    height: 300px;
    background: linear-gradient(45deg, #A35631, #338EDA);
    border-radius: 50%;
    position: absolute;
    top: -100px;
    right: -155px;
    -webkit-animation: bouncingBallAnimation 1s infinite  alternate;
    animation: bouncingBallAnimation 1s infinite  alternate;}
@-webkit-keyframes bouncingBallAnimation {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}
@keyframes bouncingBallAnimation {
  0% { transform: translateY(0); }
  100% { transform: translateY(20px); }
} 
.circle2 {
    width: 200px;
    height: 200px;
    background: linear-gradient(45deg, #1C1B43, #5254CF);
    border-radius: 50%;
    position: absolute;
    bottom: -90px;
    left: -70px;
    -webkit-animation: Circle 1s infinite  alternate;
    animation: Circle 1s infinite  alternate;}
    @-webkit-keyframes Circle {
      0% { transform: translateY(-20px); }
      100% { transform: translateY(0px); }
    }
    @keyframes Circle {
      0% { transform: translateY(-20px); }
      100% { transform: translateY(0); }
    } 
.login_form {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 40px 26px;
    width: 300px;
    /*height: 300px;*/
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.18);
}


.login_form h1 {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 8px;
}

.login_form p {
    margin-top: 0;
    margin-bottom: 26px;
}


.login_form input {
    background: transparent;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    padding: 14px 16px;
    margin-bottom: 30px;
}

.login_form input:focus {
    outline: none;
    border-color: #fff;
}

.login_form button {
    background: linear-gradient(45deg, #A35631, #338EDA);
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 14px 16px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}

.login_form button:focus {
    outline: none;
    /* box-shadow: 0 0 15px #A35631; */
}
@media screen and (min-width:1040px) {
  .login_form{
    width: 500px;
  }
}